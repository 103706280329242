<template>
  <div class="section">
    <router-link to="/" class="back">
      <img src="../../assets/back.gif" alt="" />
    </router-link>
    <Heading />
    <Piano />
    <div class="instructions">
      <p>
        1. Click on the keys or use your keyboard to play sounds. The keys are
        mapped as follows: <br /><b>
          a - C, w - C#, s - D, e - D#, d - E, f - F, t - F#, g - G, y - G#, h -
          A, u - A#, j - B</b
        >
      </p>
      <p>
        2. Click on the waveform buttons to toggle between the sine, square,
        triangle and sawtooth waveforms
      </p>
      <p>
        3. Move the sliders to modify Attack - Delay - Sustain - Releas params
      </p>
    </div>
  </div>
</template>

<script>
import "./../../../node_modules/bulma/css/bulma.css";
import Heading from "./Heading";
import Piano from "./Piano";

export default {
  name: "App",
  components: { Heading, Piano },
};
</script>

<style scoped lang="scss">
.section {
  min-height: 100vh !important;
  background-color: #e3f6f5;
  position: relative;
  .back {
    max-width: 120px;
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 10;
  }
  .instructions {
    font-size: 14px;
    margin-top: 20px;
  }
}
</style>
