<template>
  <div class="first-section-component">
    <div class="content-box">
      <img src="../assets/titulo.png" alt="annelise schulz" class="img-title" />
      <img
        src="../assets/princess.gif"
        alt="internet princess"
        class="img-subtitle"
      />
      <div class="main-content">
        <div class="play-music" @click="playSoundtrack">
          <img src="../assets/music1.gif" alt="hands pointing down" />
          <p>click here for a beautiful soundtrack</p>
        </div>
        <img
          src="../assets/programmer.gif"
          alt="hands pointing down"
          class="computer-gif"
        />
        <div>
          <img src="../assets/scroll.gif" alt="hands pointing down" />
          <p>scroll down for more awesomeness</p>
        </div>
      </div>
      <div class="under-construction">
        <img src="../assets/construction.gif" alt="construction symbol" />
        <p>I'M ALWAYS UNDER CONSTRUCTION</p>
        <img src="../assets/construction.gif" alt="construction symbol" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "first-section",

  methods: {
    playSoundtrack() {
      var audio = new Audio(require("../assets/clair-de-lune.mp3"));
      audio.play()
    },
  },
};
</script>

<style lang="scss">
.first-section-component {
  height: 100%;
  background-image: url("../assets/background.gif");
  position: relative;
  .content-box {
    max-width: 960px;
    margin: 0px auto;
  }
  .img-title {
    max-width: 300px;
    display: block;
    padding-top: 100px;
    margin-left: calc(50% - 150px);
  }
  .img-subtitle {
    display: block;
    max-width: 250px;
    padding-top: 10px;
    margin-left: calc(50% - 125px);
  }
  .main-content {
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
    font-size: 16px;
    .play-music {
      cursor: pointer;
    }
    img {
      max-width: 150px;
      &.computer-gif {
        max-width: 300px;
      }
    }
  }
  .under-construction {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    color: #ffe216;
    font-weight: 900;
    font-size: 30px;
    p {
      animation: blinker 1s linear infinite;
    }
    img {
      width: 100%;
      height: 20px;
    }
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .first-section-component {
    .img-title {
      padding-top: 30px;
    }
    .img-subtitle {
      padding-top: 0px;
    }
    .main-content {
      display: block;
      margin-top: 20px;
      img {
        max-width: 60px;
        &.computer-gif {
          display: none;
        }
      }
    }
    .under-construction {
      font-size: 20px;
    }
  }
}
</style>
