<template>
  <div class="container has-text-centered">
    <img
      src="../../assets/bananavw.gif"
      alt="banana vapor wave"
      class="banana"
    />
    <h1 class="title custom-text-dark custom-heading">bAnAnA synth</h1>
    <h2 class="subtitle">this shit is bananas</h2>
  </div>
</template>

<script>
export default {
  name: "Heading",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap");

.custom-text-dark {
  color: black;
  font-family: "Major Mono Display", monospace;
}
.custom-heading {
  font-weight: 900;
}
.custom-regular-text {
  font-weight: 300;
}

.banana {
  max-height: 150px;
}
</style>
