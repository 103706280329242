<template>
  <div class="home">
    <div class="my-section">
      <first-section></first-section>
    </div>
    <div class="my-section">
      <second-section></second-section>
    </div>
    <div class="my-section">
      <third-section></third-section>
    </div>
    <div class="my-section">
      <fourth-section></fourth-section>
    </div>
  </div>
</template>

<script>
import FirstSection from "./FirstSection.vue";
import SecondSection from "./SecondSection.vue";
import ThirdSection from "./ThirdSection.vue";
import FourthSection from "./FourthSection.vue";

export default {
  name: "Home",

  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
  },
};
</script>

<style lang="scss">
.home {
  cursor: url("../assets/happy.png"), auto;
  .my-section {
    height: 100vh;
  }
}
</style>
