<template>
  <div class="third-section-template">
    <div class="top-info">
      <img src="../assets/banana-circle.gif" alt="" class="banana-float" />
      <div>
        <p>i love music</p>
        <img src="../assets/music.gif" alt="" />
      </div>
      <div>
        <p>i love andy warhol</p>
        <img src="../assets/andy.gif" alt="" />
      </div>
      <div>
        <p>and i love coding</p>
        <img src="../assets/coding.gif" alt="" />
      </div>
      <img src="../assets/banana-circle.gif" alt="" class="banana-float" />
    </div>
    <p class="intro">so i created the BANANA SYNTH!!!!</p>
    <img src="../assets/bananaprint.png" alt="" class="banana-print" />
    <p class="intro">a synth where you play at andy's bananas</p>
    <router-link to="/banana-synth">
      <button>
        <span>CHECK IT OUT</span>
      </button>
    </router-link>
    <div class="banana-banner"></div>
  </div>
</template>

<script>
export default {
  name: "third-section",
};
</script>

<style lang="scss" scoped>
.third-section-template {
  background-image: url("../assets/background3.gif");
  background-size: cover;
  height: 100%;
  position: relative;
  .top-info {
    padding-top: 50px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    p {
      color: white;
      background: black;
      font-size: 15px;
      max-width: 80px;
    }
    img {
      max-width: 150px;
    }
  }
  .intro {
    color: black;
    background: white;
    max-width: 200px;
    margin: 0 auto;
  }
  .banana-print {
    max-width: 80%;
  }
  button {
    cursor: pointer;
    position: relative;
    padding: 1.5rem 3.5rem;
    border-radius: 3.75rem;
    line-height: 2.5rem;
    font-size: 2rem;
    font-weight: 600;

    border: 1px solid #012880;
    background-image: linear-gradient(-180deg, #ff89d6 0%, #c01f9e 100%);
    box-shadow: 0 1rem 1.25rem 0 rgba(22, 75, 195, 0.5),
      0 -0.25rem 1.5rem rgba(110, 15, 155, 1) inset,
      0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
      0 0.25rem 0.5rem 0 rgba(180, 70, 207, 1) inset;
  }

  button span {
    color: transparent;
    background-image: linear-gradient(0deg, #ee82da 0%, #fefafd 100%);
    -webkit-background-clip: text;
    background-clip: text;
    filter: drop-shadow(0 2px 2px hsla(290, 100%, 20%, 1));
  }

  button::before {
    content: "";
    display: block;
    height: 0.25rem;
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 7.5rem);
    background: #fff;
    border-radius: 100%;

    opacity: 0.7;
    background-image: linear-gradient(
      -270deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 20%,
      #ffffff 80%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  button::after {
    content: "";
    display: block;
    height: 0.25rem;
    position: absolute;
    bottom: 0.75rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 7.5rem);
    background: #fff;
    border-radius: 100%;

    filter: blur(1px);
    opacity: 0.05;
    background-image: linear-gradient(
      -270deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 20%,
      #ffffff 80%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .banana-banner {
    background-color: black;
    background-image: url("../assets/banana-background.gif");
    background-size: contain;
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0px;
    right: 0;
  }
}

@media (max-width: 768px) {
  .third-section-template {
    .top-info {
      display: flex;
      img {
        max-width: 80px;
      }
    }
    .banana-float {
      display: none;
    }
  }
}
</style>
