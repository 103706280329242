import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Home.vue";
import BananaPage from "./components/banana-synth/BananaPage.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/banana-synth",
      name: "banana-synth",
      component: BananaPage,
    },
  ],
});

export default router;
