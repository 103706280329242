<template>
  <div class="second-section-template">
    <div class="flower-rain">
      <img src="../assets/flor8.gif" alt="" />
      <img src="../assets/flor8.gif" alt="" />
      <img src="../assets/flor8.gif" alt="" />
      <img src="../assets/flor8.gif" alt="" />
      <img src="../assets/flor8.gif" alt="" />
      <img src="../assets/flor8.gif" alt="" />
      <img src="../assets/flor8.gif" alt="" />
      <img src="../assets/flor8.gif" alt="" />
    </div>
    <div class="main-content">
      <div class="description">
        <h1 class="title">Hello, I'm Annelise</h1>
        <p>
          Front-end Developer with over 8 years of experience creating
          <b> beautiful</b> and <b>functional</b> websites and web applications.
          I code beautifully simple things, and I love what I do. I'm quietly
          confident, naturally curious, and perpetually working on improving my
          chops one problem at a time.
        </p>
        <div class="linkedin">
          <span>connect with me on linkedin</span>
          <a href="https://www.linkedin.com/in/anneliseschulzz/" target="_blank">
            <img src="../assets/clickhere.gif" alt="" />
          </a>
        </div>
      </div>
      <div class="self-portrait">
        <img src="../assets/me.gif" alt="" />
      </div>
    </div>
    <div class="gifs">
      <img src="../assets/fun.gif" alt="" />
      <img src="../assets/arrow.gif" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "second-section",
};
</script>

<style lang="scss" scoped>
.second-section-template {
  height: 100%;
  background-image: url("../assets/background2.gif");
  background-size: cover;
  background-position: bottom;
  position: relative;
  overflow-x: hidden;
  .flower-rain {
    width: 100%;
    display: flex;
    margin-left: -20px;
    margin-top: -40px;
    img {
      max-width: 400px;
      max-height: 300px;
    }
  }
  .main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 900px;
    margin-left: calc(50% - 450px);
    .description {
      height: 300px;
      width: 400px;
      background: white;
      font-family: "Lucida Console", Monaco, monospace;
      .title {
        padding: 30px 20px 0px 20px;
        font-size: 18px;
      }
      p {
        font-size: 13px;
        text-align: left;
        padding: 0px 40px 20px 50px;
      }
      .linkedin {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -25px;
        font-size: 13px;
        padding-left: 50px;
        img {
          max-width: 100px;
        }
      }
    }
    .self-portrait {
      height: 300px;
      width: 400px;
      img {
        max-width: 400px;
        max-height: 300px;
      }
    }
  }
  .gifs {
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: flex-end;
    bottom: 20px;
    img {
      max-width: 130px;
    }
  }
}

@media (max-width: 768px) {
  .second-section-template {
    .main-content {
      display: block;
      margin: -90px 10px 0 10px;
      .description {
        max-width: 100%;
        height: auto;
        p {
          padding: 0px 25px 25px 25px;
        }
      }
      .self-portrait {
        width: auto;
        img {
          margin-top: 20px;
          max-width: 300px;
        }
      }
    }
    .gifs {
      position: relative;
      img {
        max-width: 80px;
      }
      
    }
  }
}
</style>
