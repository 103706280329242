<template>
  <div class="fourth-section-template">
    <div class="computer pc-row1">
      <img src="../assets/computer1.gif" />
      <img src="../assets/computer2.gif" />
      <img src="../assets/computer3.gif" />
      <img src="../assets/computer4.gif" />
      <img src="../assets/computer5.gif" />
    </div>
    <table>
      <tbody>
        <tr>
          <td>HOBBIES</td>
          <td>everything including daydreaming</td>
        </tr>
        <tr>
          <td>WHAT I'M DOING AT THE MOMENT (NOT LITERALLY!)</td>
          <td>
            I’m collecting rainbows and I play the fool! are you smiling yet??
            --- I hope so!
          </td>
        </tr>
        <tr>
          <td>QUOTES</td>
          <td>
            “Is a Sunset . . . The End or a Begining?”. "You can’t love anybody
            until you love yourself”. “How to be an artist: Stay loose and learn
            to watch snails.”
          </td>
        </tr>
        <tr class="email">
          EMAIL ME AT ANNELISESCHULZZ@GMAIL.COM
        </tr>
      </tbody>
    </table>
    <div class="computer pc-row2">
      <img src="../assets/computer6.gif" />
      <img src="../assets/computer7.gif" />
      <img src="../assets/computer8.gif" />
      <img src="../assets/computer9.gif" />
      <img src="../assets/computer10.gif" />
    </div>
    <div class="continue">
      <p>this website is not finished yet</p>
      <img src="../assets/continue.gif" />
    </div>
  </div>
</template>

<script>
export default {
  name: "fourth-section",
};
</script>

<style lang="scss" scoped>
.fourth-section-template {
  background-image: url("../assets/background4.gif");
  position: relative;
  background-size: cover;
  background-position: bottom;
  height: 100%;
  table {
    font-size: 14px;
    max-width: 500px;
    height: 320px;
    border: inset 10px yellow;
    border-style: outset;
    display: block;
    background: blue;
    color: white;
    font-family: "Lucida Console", Monaco, monospace;
    position: absolute;
    left: calc(50% - 260px);
    top: calc(50% - 160px);
    z-index: 100;
    text-align: center;
  }

  tr {
    display: table;
    &.email {
      margin: 10px;
      font-weight: bold;
      animation: blinker 1s linear infinite;
      display: block;
    }
  }

  td {
    box-shadow: inset 0 0 0 1px #fff;
    border: solid 1px #fff;
    display: table-cell;
    padding: 15px;
  }
  .computer {
    display: flex;
    align-items: center;
    img {
      max-width: 20%;
    }
    &.pc-row1 {
      position: absolute;
      top: 40px;
      width: 100%;
      justify-content: center;
    }
    &.pc-row2 {
      position: absolute;
      bottom: 40px;
      width: 100%;
      justify-content: center;
    }
  }
  .continue {
    background: red;
    color: yellow;
    z-index: 200;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 20px;
    margin: 20px;
    padding: 10px;
    img {
      max-width: 130px;
    }
  }
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .fourth-section-template {
    table {
      margin: 10px;
      position: relative;
      top: 20px;
      left: auto;
      height: 70%;
    }
  }
}
</style>
