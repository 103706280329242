<template>
  <div
    class="key"
    v-bind:class="{
      'black-key': pianoKey.type === 'black',
      'white-key': pianoKey.type === 'white',
    }"
    @mousedown="keyClick"
  ></div>
</template>

<script>
export default {
  name: "Key",
  props: ["pianoKey"],
  methods: {
    keyClick() {
      this.$emit("key-click-event", this.pianoKey);
    },
  },
};
</script>

<style>
.black-key {
  width: 64px;
  height: 96px;
  background-image: url("../../assets/banana2.png");
  z-index: 1;
  position: absolute;
  margin-top: -145px;
  margin-left: -49px;
}
.white-key {
  width: 84px;
  height: 127px;
  background-image: url("../../assets/banana.png");
}
.key {
  display: inline-block;
  background-position: revert;
  background-size: contain;
  cursor: pointer;
  border-radius: 4px;
}
.key:active {
  box-shadow: none;
}
@media only screen and (max-width: 480px),
  only screen and (max-device-width: 480px) {
  .black-key {
    width: 25px;
    height: 100px;
    background-color: black;
    z-index: 1;
    position: absolute;
    margin-top: -169px;
    margin-left: -13px;
  }
  .white-key {
    width: 40px;
    height: 170px;
    background-color: #ffffff;
    border: 1px solid black;
  }
}
</style>
